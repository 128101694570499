import { Appointment } from '@medplum/fhirtypes'
import dayjs, { Dayjs } from 'dayjs'

export const getPeriodWithTimezone = (
  appointment: Appointment,
  timezone?: string
): { start: Dayjs; end: Dayjs } | null => {
  const start = appointment.start
  const end = appointment.end
  if (!start || !end) return null

  if (timezone) {
    return {
      start: dayjs(start).tz(timezone),
      end: dayjs(end).tz(timezone)
    }
  }

  return {
    start: dayjs(start),
    end: dayjs(end)
  }
}
