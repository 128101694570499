import { ResourceArray } from '@medplum/core'
import { Resource } from '@medplum/fhirtypes'

export const extractCursorFromResponse = <T extends Resource>(
  resources: ResourceArray<T>
): string | null => {
  const nextLinkUrl = resources.bundle.link?.find((link) => link.relation === 'next')?.url
  const urlSearchParams = new URLSearchParams(nextLinkUrl)

  const newCursor = urlSearchParams.get('_cursor')

  return newCursor
}

type CursorBasedFetchFunction<T> = (
  cursor?: string
) => Promise<{ cursor: string | null; results: T[] }>

export const fetchWithCursor = async <T>(
  cursorBasedFetchFunction: CursorBasedFetchFunction<T>
): Promise<T[]> => {
  let cursor: string | undefined
  let resultsForCursor: T[]
  const aggregatedResultsForCursor: T[] = []

  do {
    const { cursor: newCursor, results } = await cursorBasedFetchFunction(cursor)
    cursor = newCursor ?? undefined
    resultsForCursor = results

    aggregatedResultsForCursor.push(...resultsForCursor)
  } while (resultsForCursor && cursor)

  return aggregatedResultsForCursor
}
