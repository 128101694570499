import { Weekdays } from 'utils/constants'
import { SocAssessmentQuestionnaireItem } from '../SocAssessment/types/questionnaire'
import { RecertAssessmentQuestionnaireItem } from '../RecertAssessment/types/questionnaire'
import { RevisionOfParAssessmentQuestionnaireItem } from '../RevisionOfParAssessment/types/questionnaire'
import { RocAssessmentQuestionnaireItem } from '../RocAssessment/types/questionnaire'
import { PatAssessmentQuestionnaireItem } from '../PatAssessment/types/questionnaire'
import { DischargeAssessmentQuestionnaireItem } from '../DischargeAssessment/types/questionnaire'
import { SupervisoryAssessmentQuestionnaireItem } from '../SupervisoryAssessment/types/questionnaire'

export type SharedCareQuestionnaireItemType =
  | SocAssessmentQuestionnaireItem
  | RecertAssessmentQuestionnaireItem
  | RevisionOfParAssessmentQuestionnaireItem
  | RocAssessmentQuestionnaireItem

export type AllQuestionnaireItemType =
  | PatAssessmentQuestionnaireItem
  | SocAssessmentQuestionnaireItem
  | RecertAssessmentQuestionnaireItem
  | RevisionOfParAssessmentQuestionnaireItem
  | DischargeAssessmentQuestionnaireItem
  | RocAssessmentQuestionnaireItem
  | SupervisoryAssessmentQuestionnaireItem

type SharedCareQuestionnaireItemKey = keyof typeof SocAssessmentQuestionnaireItem &
  keyof typeof RecertAssessmentQuestionnaireItem &
  keyof typeof RevisionOfParAssessmentQuestionnaireItem &
  keyof typeof RocAssessmentQuestionnaireItem

export interface InterventionInfo {
  id?: number
  category?: string
  interventionGoal?: string
  task?: string
  indication?: string
  type?: string
  instruction?: string
  patientCenteredGoal?: string
  additionalDetails?: string
  visitNumbers?: number[]
  frequency?: string[]
}

// A shared questionnaire item to help us retrieve keys that are common to both
// SOC and Recerts, Revision of PAR and ROC
export const SharedCareQuestionnaireItem = (
  key: SharedCareQuestionnaireItemKey
): SharedCareQuestionnaireItemType => {
  // Since the key is shared, we can use either questionnaire item to retrieve the key.
  // In our case, we use the SOC questionnaire item.
  return SocAssessmentQuestionnaireItem[key]
}

export const TASK_FREQUENCIES = Object.values(Weekdays).map((weekday) => weekday)
