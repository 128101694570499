import { useCallback, useMemo } from 'react'
import { debounce } from 'lodash'
import { LinkIdWithItem, useAssessmentContext } from 'contexts/AssessmentContext'
import { QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE } from 'components/Assessments/questionnaire'
import { encodeValueForAnswerType, getValueKeyForAnswerType } from 'components/Assessments/helpers'
import { AllQuestionnaireItemType } from './constants'

export interface LinkIdWithValue {
  linkId: AllQuestionnaireItemType
  value: unknown
}

type OnValueChange = (...linkIdsWithValues: LinkIdWithValue[]) => void

const useOnValueChange = (): OnValueChange => {
  const { flatAnswers, updateFlatAnswers } = useAssessmentContext()

  // Used for simple top-level one-layer-deep answer changes
  const onValueChange = useCallback(
    (...linkIdsWithValues: LinkIdWithValue[]) => {
      const linkIdsWithAnswers = linkIdsWithValues.map(({ linkId, value }): LinkIdWithItem => {
        const newAnswerItem = flatAnswers[linkId] ?? { linkId }

        const answerType = QUESTIONNAIRE_ITEM_TO_ANSWER_TYPE[linkId]
        if (answerType === undefined)
          throw new Error(`Could not find answer type for questionnaire item ${linkId}`)

        const valueAsArray = Array.isArray(value) ? value : [value]

        newAnswerItem.answer = valueAsArray.map((v) => ({
          [getValueKeyForAnswerType(answerType)]: encodeValueForAnswerType(v, answerType)
        }))
        // Since an item cannot have both an item and an answer, we need to remove the item if we add answers
        newAnswerItem.item = undefined

        return {
          linkId,
          item: newAnswerItem
        }
      })

      updateFlatAnswers(...linkIdsWithAnswers)
    },
    [flatAnswers, updateFlatAnswers]
  )

  const memoizedOnValueChange = useMemo(() => {
    return debounce(onValueChange, 300)
  }, [onValueChange])

  return memoizedOnValueChange
}

export default useOnValueChange
