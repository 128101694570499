import { MedplumClient } from '@medplum/core'
import { ALLOWED_STATUS_CODES, medplum } from './medplumClient'
import { captureException, SentrySources, TransactionNames } from './sentry'

export class HttpClient {
  private medplum: MedplumClient
  private accessToken: string | undefined

  constructor() {
    this.medplum = medplum
    this.accessToken = this.medplum.getAccessToken()
    if (!this.accessToken) {
      throw new Error('Access token is required')
    }
  }

  async fetch(url: string, options?: any): Promise<Response> {
    const { fetch: originalFetch } = window

    const headers = {
      ...options?.headers,
      'Content-Type': options?.headers?.['Content-Type']
        ? options?.headers?.['Content-Type']
        : 'application/json',
      Authorization: `Bearer ${this.accessToken}`
    }

    let response

    try {
      response = await originalFetch(url, {
        ...options,
        headers
      })

      if (response?.status && !ALLOWED_STATUS_CODES.includes(parseInt(response?.status))) {
        captureException(new Error(`Failed to fetch ${url}: got status ${response.status}`), {
          tags: { source: SentrySources.FASTAPI_FETCH_MIDDLEWARE, url },
          extras: { params: options, info: response },
          transactionName: TransactionNames.FETCH
        })
      }

      return response
    } catch (error) {
      captureException(error, {
        tags: { source: SentrySources.FASTAPI_FETCH_MIDDLEWARE, url },
        extras: { params: options, errors: [error] },
        transactionName: TransactionNames.FETCH
      })

      throw error
    }
  }
}
