import { useRef, useState } from 'react'
import { Button, Dropdown, Form } from 'antd'
import styled from 'styled-components'
import { ClipboardPlus, PlusOutlined } from 'public/assets/icons'
import NoteTypesFormContainer from './NoteForm/NoteTypesFormContainer'

type ClinicalNoteProps = {
  source: 'navbar' | 'assessment'
  loadPatientNotesData?: () => void
}

const CreateNote: React.FC<ClinicalNoteProps> = ({ source, loadPatientNotesData }): JSX.Element => {
  const [showForm, setShowForm] = useState(false)
  const [form] = Form.useForm()
  const buttonRef = useRef(null)

  const closePopupForm = (): void => {
    setShowForm(false)
    form.resetFields()
  }

  const isWithinAssessment = source === 'assessment'

  return (
    <Form form={form} colon={false}>
      <div ref={buttonRef} style={{ float: 'right' }}>
        <Dropdown
          placement="bottomRight"
          dropdownRender={() => (
            <NoteTypesFormContainer
              closePopupForm={closePopupForm}
              form={form}
              source={source}
              loadPatientNotesData={loadPatientNotesData}
            />
          )}
          trigger={['click']}
          destroyPopupOnHide
          open={showForm}
          overlayStyle={{
            width: '500px',
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            margin: '20px',
            padding: '20px'
          }}
        >
          {!isWithinAssessment ? (
            <ClipboardPlusStyled
              onClick={() => {
                setShowForm(!showForm)
                if (showForm) {
                  closePopupForm()
                }
              }}
              style={{ color: showForm ? '#2E90FA' : '#667085' }}
            />
          ) : (
            <Button onClick={() => setShowForm(!showForm)}>
              <PlusOutlinedStyled height="12px" width="12px" />
              Create New Note
            </Button>
          )}
        </Dropdown>
      </div>
    </Form>
  )
}

const ClipboardPlusStyled = styled(ClipboardPlus)<{ showForm: boolean }>`
  color: ${({ showForm }) => (showForm ? '#2E90FA' : '#667085')};
`

const PlusOutlinedStyled = styled(PlusOutlined)`
  margin-right: 0.5rem;
`

export default CreateNote
