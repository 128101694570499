import { useFlags } from 'launchdarkly-react-client-sdk'

export enum FeatureFlagKey {
  AbbyAidOnboarding = 'abbyaid_onboarding',
  AbbyosVisitBilling = 'abbyos_visit_billing',
  DebugLogs = 'debug-logs',
  Flows = 'flows',
  EnableEditingOrders = 'enable-editing-orders',
  isAutoFaxEnabled = 'auto_fax',
  isServicePlanEnabled = 'service_plan',
  isNewPatientChart = 'patient_chart_revamp',
  priorAuthOrder = 'prior-auth-order',
  oneYearPar = 'one-year-par'
}

export type FeatureFlagName = keyof FeatureFlags

export interface FeatureFlags {
  abbyaidOnboarding: boolean
  abbyosVisitBilling: boolean
  debugLogs: boolean
  flows: boolean
  enableEditingOrders: boolean
  isAutoFaxEnabled: boolean
  isServicePlanEnabled: boolean
  isNewPatientChart: boolean
  priorAuthOrder: boolean
  oneYearPar: boolean
}

const useFeatureFlags = (): FeatureFlags => {
  const flags = useFlags()

  const featureFlags = {
    abbyaidOnboarding: flags[FeatureFlagKey.AbbyAidOnboarding],
    abbyosVisitBilling: flags[FeatureFlagKey.AbbyosVisitBilling],
    debugLogs: flags[FeatureFlagKey.DebugLogs],
    flows: flags[FeatureFlagKey.Flows],
    enableEditingOrders: flags[FeatureFlagKey.EnableEditingOrders],
    isAutoFaxEnabled: flags[FeatureFlagKey.isAutoFaxEnabled],
    isServicePlanEnabled: flags[FeatureFlagKey.isServicePlanEnabled],
    isNewPatientChart: flags[FeatureFlagKey.isNewPatientChart],
    priorAuthOrder: flags[FeatureFlagKey.priorAuthOrder],
    oneYearPar: flags[FeatureFlagKey.oneYearPar]
  }

  return featureFlags
}

export default useFeatureFlags
