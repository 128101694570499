import { getReferenceString } from '@medplum/core'
import {
  Questionnaire,
  QuestionnaireItem,
  QuestionnaireItemInitial,
  QuestionnaireResponse,
  QuestionnaireResponseItem,
  QuestionnaireResponseItemAnswer
} from '@medplum/fhirtypes'
import { Alert, Typography } from 'antd'
import styled from 'styled-components'
import { removeNullValues } from 'utils/helpers'

const { Text } = Typography

export const buildInitialResponse = (questionnaire: Questionnaire): QuestionnaireResponse => {
  const response: QuestionnaireResponse = {
    resourceType: 'QuestionnaireResponse',
    questionnaire: getReferenceString(questionnaire),
    item: buildInitialResponseItems(questionnaire.item)
  } as QuestionnaireResponse

  return response
}

export const buildInitialResponseItems = (
  items: QuestionnaireItem[] | undefined
): QuestionnaireResponseItem[] => {
  return items?.map(buildInitialResponseItem) ?? []
}

export const buildInitialResponseItem = (item: QuestionnaireItem): QuestionnaireResponseItem => {
  return {
    linkId: item.linkId,
    text: item.text,
    item: buildInitialResponseItems(item.item),
    answer: item.initial?.map(buildInitialResponseAnswer) ?? undefined
  }
}

const buildInitialResponseAnswer = (
  answer: QuestionnaireItemInitial
): QuestionnaireResponseItemAnswer => {
  const refinedAnswer = removeNullValues(answer)
  return { ...refinedAnswer }
}

export const rebuildResponseFromFlat = (
  flattenedData: Record<string, QuestionnaireResponseItem>
): QuestionnaireResponseItem[] => {
  const response: QuestionnaireResponseItem[] = []
  const processedLinkIds = new Set()

  const addToParentItem = (
    item: QuestionnaireResponseItem,
    parentItem: QuestionnaireResponseItem
  ): void => {
    if (!processedLinkIds.has(parentItem.linkId)) {
      processedLinkIds.add(parentItem.linkId)
      parentItem.item = parentItem.item || []
      parentItem.item.push(item)
    }
  }

  const addToResponse = (item: QuestionnaireResponseItem): void => {
    if (!processedLinkIds.has(item.linkId)) {
      processedLinkIds.add(item.linkId)

      if (!item.linkId?.includes('/')) {
        response.push(item)
      } else {
        const parentLinkId = item.linkId.split('/').slice(0, -1).join('/')
        const parentItem = flattenedData[parentLinkId]
        if (parentItem) {
          addToParentItem(item, parentItem)
        }
      }
    }
  }

  Object.values(flattenedData).forEach((item) => {
    if (item?.linkId) {
      addToResponse(item)
    }
  })

  return response
}

export const flattenQuestionnaireResponseItem = (
  response: QuestionnaireResponseItem[]
): Record<string, QuestionnaireResponseItem> => {
  const flattenedData: Record<string, QuestionnaireResponseItem> = {}

  const flattenItem = (item: QuestionnaireResponseItem[]): void => {
    item.forEach((entry) => {
      if (entry.linkId) {
        flattenedData[entry.linkId] = entry
        if (entry.item) {
          flattenItem(entry.item)
        }
      }
    })
  }
  response.forEach((item) => {
    flattenItem([item])
  })
  return flattenedData
}

export const formatModalText = (
  errors: string[],
  dataCompletenessErrors?: string[]
): React.ReactElement => (
  <>
    <Text>Warning!</Text>
    <ModalErrorContainer>
      {errors?.map((error) => (
        <Alert showIcon type="warning" key={error} message={error} />
      ))}
      {dataCompletenessErrors?.map((error) => (
        <Alert showIcon type="error" key={error} message={error} />
      ))}
    </ModalErrorContainer>
    <Text>Please type your name in the text box below to confirm you want to proceed.</Text>
  </>
)

const ModalErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1rem 0;
`
