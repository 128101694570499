export enum AbbyAccessPolicy {
  CARE_OPS = 'CareOps Access Policy',
  CLINICAL = 'Clinical Access Policy',
  CAREGIVER = 'Caregiver Access Policy',
  CHAP_AUDITOR = 'CHAP Auditor Access Policy',
  BILLING = 'Billing Access Policy',
  ADMIN = 'Admin'
}

export const parseAccessPolicies = (accessPolicyList: string[]): AbbyAccessPolicy[] => {
  const policies: Set<AbbyAccessPolicy> = new Set()

  for (const policy of accessPolicyList) {
    const policyId = policy.split('/')[1]

    // CARE_OPS
    if ([process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CARE_OPS].includes(policyId)) {
      policies.add(AbbyAccessPolicy.CARE_OPS)
    }

    //CLINICAL
    if ([process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CLINICAL].includes(policyId)) {
      policies.add(AbbyAccessPolicy.CLINICAL)
    }

    // CAREGIVER
    if (
      [
        process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CAREGIVER_PATIENT_PARAMETERIZED,
        process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CAREGIVER_PARAMETERIZED
      ].includes(policyId)
    ) {
      policies.add(AbbyAccessPolicy.CAREGIVER)
    }

    // CHAP AUDITOR
    if (
      [
        process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CHAP_AUDIT_PATIENT_PARAMETERIZED,
        process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_CHAP_AUDIT_CAREGIVER_PARAMETERIZED
      ].includes(policyId)
    ) {
      policies.add(AbbyAccessPolicy.CHAP_AUDITOR)
    }

    // BILLING
    if ([process.env.NEXT_PUBLIC_ACCESS_POLICY_ID_BILLING_PARAMETERIZED].includes(policyId)) {
      policies.add(AbbyAccessPolicy.BILLING)
    }
  }
  return Array.from(policies)
}

export const isAccessPolicyAdminOrCareopsOrClinical = (
  accessPolicyList: AbbyAccessPolicy[]
): boolean => {
  return [AbbyAccessPolicy.ADMIN, AbbyAccessPolicy.CARE_OPS, AbbyAccessPolicy.CLINICAL].some(
    (policy) => accessPolicyList.includes(policy)
  )
}

export const isAccessPolicyAdminOrCareops = (accessPolicyList: AbbyAccessPolicy[]): boolean => {
  return [AbbyAccessPolicy.ADMIN, AbbyAccessPolicy.CARE_OPS].some((policy) =>
    accessPolicyList.includes(policy)
  )
}

export const isAccessPolicyBilling = (accessPolicyList: AbbyAccessPolicy[]): boolean => {
  return accessPolicyList.includes(AbbyAccessPolicy.BILLING)
}
