import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
import { Menu } from 'public/assets/icons'
import CreateNote from 'components/Notes/components/CreateNoteDropdown'
import HeaderDropdown from './HeaderDropdown'

export const Head = ({ onClick }): JSX.Element => {
  return (
    <>
      <HeaderContainer>
        <StyledMenuIcon onClick={onClick}>
          <Menu style={{ color: '#667085' }} />
        </StyledMenuIcon>

        <HeaderContainerRight>
          <Divider type="vertical" />

          <StyledBellIcon>
            <CreateNote source="navbar" />
          </StyledBellIcon>

          <Divider type="vertical" />

          <StyledDropdown>
            <HeaderDropdown />
          </StyledDropdown>
        </HeaderContainerRight>
      </HeaderContainer>
    </>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 24px;
`

const HeaderContainerRight = styled.div`
  display: flex;
  width: 80px;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`

const StyledMenuIcon = styled.div`
  cursor: pointer;
  transition: color 0.3s;
`

const StyledBellIcon = styled.div`
  cursor: pointer;

  svg {
    display: block;
    color: #667085;
  }
`

const StyledDropdown = styled.div`
  cursor: pointer;
`
