import React from 'react'
import { useMedplum } from '@medplum/react'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown } from 'antd'
import useSafeNavigation from 'hooks/useSafeNavigation'
import { formatPrimaryName } from 'utils/names'

export const HeaderDropdown = (): JSX.Element => {
  const medplum = useMedplum()
  const { safePush } = useSafeNavigation()

  const profile = medplum.getProfile()
  const profilePhoto = profile?.photo?.[0].url ?? null
  // Since we don't allow uploading of a profile photo yet, display the initials in the avatar instead
  const profileName = formatPrimaryName(profile?.name)
  const profileNameArray = profileName?.split(' ')
  const nameInitials = [profileNameArray?.at(0)?.at(0), profileNameArray?.at(-1)?.at(0)].join('')

  const handleLogOut = (): void => {
    medplum.signOut()

    safePush('/login')
  }

  const items: MenuProps['items'] = [
    {
      label: 'Settings',
      key: 'settings',
      icon: <SettingOutlined />,
      onClick: (): void => safePush('/settings')
    },
    {
      type: 'divider'
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: handleLogOut
    }
  ]

  const menuProps = {
    items
  }

  return (
    <Dropdown menu={menuProps} overlayStyle={{ width: '153px' }}>
      {nameInitials ? <Avatar>{nameInitials}</Avatar> : <Avatar src={profilePhoto} />}
    </Dropdown>
  )
}

export default HeaderDropdown
