import gql from 'gql-tag'

export const BASE_ENCOUNTER_PARTICIPANT_FRAGMENT = gql`
  fragment BaseEncounterParticipant on EncounterParticipant {
    individual {
      resource {
        ...BasePractitioner
      }
    }
    type {
      coding {
        system
        code
        display
      }
    }
  }
`

export const BASE_ENCOUNTER_FRAGMENT = gql`
  fragment BaseEncounter on Encounter {
    id
    status
    class {
      code
    }
    identifier {
      system
      value
    }
    period {
      start
      end
    }
    reasonCode {
      text
    }
    subject {
      resource {
        ...BasePatient
      }
    }
    episodeOfCare {
      reference
      display
    }
    participant {
      ...BaseEncounterParticipant
    }
    appointment {
      reference
    }
    extension {
      url
      valueString
    }
  }
  ${BASE_ENCOUNTER_PARTICIPANT_FRAGMENT}
`

// ————————————————————————————————————————————————————————————————————————————————
// Nested types used for helper functions
// ————————————————————————————————————————————————————————————————————————————————

export const BASE_ENCOUNTER_WITH_APPOINTMENTS_FRAGMENT = gql`
  fragment BaseEncounterWithAppointments on Encounter {
    ...BaseEncounter
    appointment {
      resource {
        ...BaseAppointment
      }
    }
  }
`

export const BASE_ENCOUNTER_WITH_VISIT_QA_TASKS_FRAGMENT = gql`
  fragment BaseEncounterWithVisitQATasks on Encounter {
    ...BaseEncounter
    visitQATasks: TaskList(_reference: focus) {
      ...BaseTask
    }
  }
`

export const BASE_ENCOUNTER_WITH_APPOINTMENTS_AND_VISIT_QA_TASKS_FRAGMENT = gql`
  fragment BaseEncounterWithAppointmentsAndVisitQATasks on Encounter {
    ...BaseEncounter
    appointment {
      resource {
        ...BaseAppointment
      }
    }
    visitQATasks: TaskList(_reference: focus) {
      ...BaseTask
    }
  }
`

export const BASE_ENCOUNTER_WITH_EPISODE_OF_CARE_FRAGMENT = gql`
  fragment BaseEncounterWithEpisodeOfCare on Encounter {
    ...BaseEncounter
    episodeOfCare {
      resource {
        ...BaseEpisodeOfCare
      }
    }
  }
`

export const BASE_ENCOUNTER_WITH_CERT_PERIOD_FRAGMENT = gql`
  fragment BaseEncounterWithCertPeriod on Encounter {
    ...BaseEncounter
    episodeOfCare {
      resource {
        ...BaseEpisodeOfCare
      }
    }
  }
`
