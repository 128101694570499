export enum CareTeamMemberRole {
  Caregiver = 'Caregiver',
  RegisteredNurse = 'Registered Nurse',
  OrderingPhysician = 'Ordering Physician',
  EmergencyContact = 'Emergency Contact',
  AlternateCaregiver = 'Alternative Caregiver',
  // These roles are not currently in use and synomymous with OrderingPhysician
  Physician = 'Physician',
  PrimaryCarePhysician = 'Primary Care Physician'
}
